import { render, staticRenderFns } from "./image-common-config.vue?vue&type=template&id=18181a3d&scoped=true&"
import script from "./image-common-config.vue?vue&type=script&lang=ts&"
export * from "./image-common-config.vue?vue&type=script&lang=ts&"
import style0 from "./image-common-config.vue?vue&type=style&index=0&id=18181a3d&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18181a3d",
  null
  
)

export default component.exports