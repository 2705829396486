
import { Component, Prop, Vue } from 'vue-property-decorator'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import PropertyModal from '@/views/project/webapi/entity-builder-system/components/property-panel/modal/property-modal.vue'
import imageCommonConfigApi, { ImageCommonConfig } from '@/api/maintain/image-common-config'
import { BaseModal } from '@/libs/modal-helper'

/// 新增环境变量模特框
@Component({
  name: 'dataList',
  components: { ExpendSearchPanel, PropertyModal }
})
export default class ImageCommonConfigModal extends Vue implements BaseModal {
  /// 镜像id
  @Prop({
    type: Number,
    required: true
  })
  imageId!: number;

  /// 修改时传入环境变量
  @Prop({
    type: Object,
    required: false,
    default: undefined
  })
  item?: ImageCommonConfig;

  show (): void {
    (this.$refs.dlg as PropertyModal).show()
  }

  close (): void {
    (this.$refs.dlg as PropertyModal).close()
  }

  /// 页面数据
  formData: ImageCommonConfig = this.item || { imageId: this.imageId, configType: 5 };

  /// 环境变量key校验方式
  async validateKey (rule: any, value: string, callback: Function) {
    if (!value || value === '') {
      callback(new Error('环境变量key不能为空'))
    }
    if (!/^[A-Z_]+$/.test(value)) {
      callback(new Error('环境变量key格式不正确'))
    }
    const res = await imageCommonConfigApi.query({ page: 1, pageSize: 1, sorts: [], imageId: this.imageId, configKey: value })
    if (res.data?.items[0] && res.data.items[0].id !== this.formData.id) {
      callback(new Error('环境变量key不能重复'))
    }
    callback()
  }

  /// 环境变量名校验方式
  async validateName (rule: any, value: string, callback: Function) {
    if (!value || value === '') {
      callback(new Error('环境变量名不能为空'))
    }
    const res = await imageCommonConfigApi.query({ page: 1, pageSize: 1, sorts: [], imageId: this.imageId, configName: value })
    if (res.data?.items[0] && res.data.items[0].id !== this.formData.id) {
      callback(new Error('环境变量名不能重复'))
    }
    callback()
  }

  /// 校验规则
  rules = {
    configKey: [{ required: true, validator: this.validateKey, trigger: 'blur' }],
    configName: [{ required: true, validator: this.validateName, trigger: 'blur' }]
  };

  /// 确认事件
  doSave () {
    (this.$refs as any).formValidate.validate((valid: boolean) => {
      if (valid) {
        this.$emit('onOk', this.formData)
      } else {
        this.$Message.error('数据校验失败')
      }
    })
  }

  /// 取消事件
  doCancel () {
    this.$emit('onCancel')
  }
}
