
import { Component, Prop, Vue } from 'vue-property-decorator'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import imageCommonConfigApi, {
  ImageCommonConfig
} from '@/api/maintain/image-common-config'
import showAddCommonConfigModal from './add-image-common-config-modal'
/// 镜像环境变量列表
@Component({
  name: 'dataList',
  components: { ExpendSearchPanel }
})
export default class ImageCommonConfigVue extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  imageId!: number;

  /// 页面数据
  pageData: ImageCommonConfig[] = [];
  /// 选中项id
  selectIds: number[]=[]

  created () {
    this.loadData()
  }

  /// 加载数据
  loadData () {
    imageCommonConfigApi
      .query({ page: 1, pageSize: 1000, sorts: [], imageId: this.imageId })
      .then((res) => {
        this.pageData = res.data?.items || []
      })
  }

  /// 修改数据项
  editRow (val: any) {
    showAddCommonConfigModal(
      this.imageId,
      async (item) => {
        await imageCommonConfigApi.updateItem(item)
        this.$Message.success('操作成功')
        this.loadData()
        return Promise.resolve()
      },
      val
    )
  }

  /// 选中项改变
  selectionChange (val: any) {
    this.selectIds = val.records.map((e: ImageCommonConfig) => e.id) || []
  }

  /// 删除数据项
  deleteRow (ids: number[]) {
    if (ids.length < 1) {
      this.$Message.error('请先选择数据项')
      return
    }
    this.$Modal.confirm({
      title: '确认',
      content: '<p>确认要删除' + ids.length + '条变量吗？</p>',
      onOk: () => {
        imageCommonConfigApi.deleteItemByIds(ids).then(() => this.loadData())
      }
    })
  }

  /// 添加镜像环境变量
  showAddModel () {
    showAddCommonConfigModal(this.imageId, async (item) => {
      await imageCommonConfigApi.insertItem(item)
      this.$Message.success('操作成功')
      this.loadData()
      return Promise.resolve()
    })
  }

  close () {
    const path = this.$route.params.from
    this.$router.push(path)
  }
}
