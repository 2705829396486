import { render, staticRenderFns } from "./image-default-config.vue?vue&type=template&id=38eb4f6a&scoped=true&"
import script from "./image-default-config.vue?vue&type=script&lang=ts&"
export * from "./image-default-config.vue?vue&type=script&lang=ts&"
import style0 from "./image-default-config.vue?vue&type=style&index=0&id=38eb4f6a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38eb4f6a",
  null
  
)

export default component.exports