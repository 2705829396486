import DefaultApiService, { BaseEntity, BaseHasTimeEntity } from '@/libs/api-request'

/**
  * 实例接口
  */
export interface ImageCommonConfig extends BaseEntity {

    // 镜像id
    imageId?: number;

    // 配置类型
    configType?: number;

    // 配置key
    configKey?: string;

    // 配置名称
    configName?: string;

    // 配置值
    configValue?: string;

    // 配置说明
    memo?: string;
}

/**
  * 镜像默认配置服务请求api接口
  */
class ImageImageCommonConfigConfigApi extends DefaultApiService<ImageCommonConfig> {
  constructor () {
    super('/api/maintain/images-common-config')
  }
}

export default new ImageImageCommonConfigConfigApi()
