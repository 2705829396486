
import { BaseConfig } from '@/api/maintain/base-config'
import ImageDefaultConfigApi, {
  ImageDefaultConfig
} from '@/api/maintain/image-default-config'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfigForm from '@/components/application/config-form.vue'

@Component({
  name: 'ImageDafaultConfigPage',
  components: {
    ConfigForm
  }
})
export default class ImageDafaultConfigPage extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  readonly id!: number;

  @Prop({
    type: Boolean,
    default: true,
    required: false
  })
  readonly viewFlag!: boolean

  /** 保存配置方法 */
  onSave (data: BaseConfig) {
    if (data.id) {
      return ImageDefaultConfigApi.updateItem(data as ImageDefaultConfig)
    }
    return ImageDefaultConfigApi.insertItem({
      ...data,
      imageId: this.id
    } as ImageDefaultConfig)
  }

  /** 删除方法 */
  onDelete (datas: Array<BaseConfig>) {
    return ImageDefaultConfigApi.deleteItemByIds(datas.map((e) => e.id!))
  }

  /** 拉取数据方法 */
  loadFromServer () {
    return ImageDefaultConfigApi.getConfigsByImageId(this.id)
  }
}
