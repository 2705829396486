
import { Prop, Vue, Component, Model } from 'vue-property-decorator'

// 选择组件
@Component({ name: 'ImageTypeSelector', components: {}, mixins: [] })
export default class ImageTypeSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: string

  curValue = this.value || null

  @Prop({
    type: String,
    default: '请选择'
  })
  placeholder!: string

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  loading = false

  datas: Array<any> = []

  created () {
    this.loadDataFromServer()
  }

  onChange (newValue: string) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
  }

  doCancel () {
    this.curValue = null
    this.$emit('input', undefined)
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.datas = [
      {
        value: 1,
        label: '注册中心'
      },
      {
        value: 2,
        label: '网关'
      },
      {
        value: 3,
        label: '配置中心'
      },
      {
        value: 4,
        label: '安全中心'
      },
      {
        value: 5,
        label: '关系数据库'
      },
      {
        value: 6,
        label: '缓存数据库'
      },
      {
        value: 7,
        label: 'nosql数据库'
      },
      {
        value: 8,
        label: '消息服务'
      },
      {
        value: 9,
        label: 'nginx'
      },
      {
        value: 99,
        label: '其他'
      }
    ]
  }
}
