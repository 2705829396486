
import { showModal } from '@/libs/modal-helper'
import { ImageCommonConfig } from '@/api/maintain/image-common-config'
import AddCommonConfigModal from './add-image-common-config-modal.vue'

/**
 * 显示新增环境变量模块框
 */
export default function showAddCommonConfigModal (id: number, onOk?: (data: ImageCommonConfig) => Promise<void>, item?: ImageCommonConfig) {
  showModal<ImageCommonConfig>(AddCommonConfigModal, {
    props: {
      imageId: id,
      item: item
    }
  }, true, onOk, undefined)
}
