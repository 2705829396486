import DefaultApiService from '@/libs/api-request'
import { DataApiResult } from '@/libs/http-request'
import { BaseConfig } from './base-config'

/** 镜像默认配置 */
export interface ImageDefaultConfig extends BaseConfig {
  /** imageId */
  imageId: number;
 }

/**
  * 镜像默认配置服务请求api接口
  */
class ImageDefaultConfigApi extends DefaultApiService<ImageDefaultConfig> {
  constructor () {
    super('/api/maintain/images-default-config')
  }

  /**
   * 通过imageid取得配置列表
   * @param imageId
   * @returns
   */
  getConfigsByImageId (imageId: number) {
    return this.requestList(`${imageId}`, undefined, 'GET')
  }

  /**
    * 取得id对应的对象的值
    * @param id 要删除记录的主键id
    */
  public getItemById (id: number): Promise<DataApiResult<ImageDefaultConfig>> {
    return this.request(`${id}`, undefined, 'GET')
  }
}

export default new ImageDefaultConfigApi()
