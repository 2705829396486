
import { Component, Watch } from 'vue-property-decorator'
import DataFormPageView from '@/frame/share/data-form-page.vue'
import StateDataFormPage from '@/frame/share/state-form-page'
import CollapsePanel from '@/components/collapse-panle.vue'
import StateFormItem from '@/components/state-formitem.vue'
import StateButton from '@/components/state-formbutton.vue'
import { Form } from 'view-design'

import ImagesResourceApi, { ImagesResource } from '@/api/maintain/image-resource'
import ImageDafaultConfigPage from './image-default-config.vue'
import { StateValue } from 'xstate/lib/types'
import ImageCommonConfigVue from './image-common-config.vue'
import { getterUserInfo } from '@/store'
import ImageTypeSelector from '@/components/selector/image-type-selector.vue'

@Component({
  name: 'dataPage',
  components: {
    DataFormPageView,
    CollapsePanel,
    StateFormItem,
    StateButton,
    ImageDafaultConfigPage,
    ImageCommonConfigVue,
    ImageTypeSelector
  }
})
export default class DataPage extends StateDataFormPage<ImagesResource, any> {
  protected serverState = '';

  loading = false;
  curValue = 0;
  applicationList: Array<any> = [];
  readonly = false;
  /**
   * 当前表单数据
   */
  formData: ImagesResource = {};

  appInstanceId = 0;

  serverPort = '3306';
  logsPath = '';
  dataPath = '';
  dataBaseName = '';
  rootName = '';
  imageId: number|undefined=-1
  // 新增时改变为false达到刷新页面的目的
  showTab = true

  pageState: StateValue | undefined=''

  /// 校验规则
  rules = {
    imageName: [
      { required: true, message: '镜像名称不允许为空', trigger: 'blur' },
      {
        pattern: '^[^\u4e00-\u9fa5]{0,}$',
        message: '不能输入中文镜像仓库名',
        trigger: 'blur'
      }
    ],
    name: [
      {
        required: true,
        message: '镜像标题不能为空',
        trigger: 'blur'
      }
    ],
    descriptUrl: [
      {
        pattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\*\\+,;=.]+$/,
        trigger: 'blur',
        message: '请输入正确的地址'
      }
    ]
  };

  defaultPost = 3306;
  runningCode = '无';
  netStatus = false;
  tabName='name1'

  serverFormState: any= undefined

  addRules = {
    name: [{ required: true, message: '应用名字不允许为空', trigger: 'blur' }]
  };

  created () {
    this.initFromState({})
    this.serverFormState = this.formStateSerive
    this.pageState = this.formState?.value
  }

  /**
   * 当前用户的项目编辑权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canOperateImageResource
  }

  /**
   * 加载指定id的表单数据
   */
  onLoadFormData (id: number): Promise<ImagesResource> {
    this.imageId = id
    return new Promise<ImagesResource>((resolve, reject) => {
      ImagesResourceApi.getItemById(id)
        .then((response) => {
          resolve(response.data!)
        })
        .catch((err: Error) => reject(err))
    })
  }

  /**
  * 生成新增的表单数据
  */
  onCreateNewFormData (): Promise<ImagesResource> {
    return new Promise<ImagesResource>((resolve, reject) => {
      resolve({
        id: undefined,
        name: ''
      })
    })
  }

  doFormValidate (callback: (valid?: boolean | undefined) => void) {
    (this.$refs.form as Form).validate((valid) => callback(valid))
  }

  /**

  * 执行新增数据保存
   */
  onInsertFormData (): Promise<ImagesResource> {
    this.formData.title = this.formData.name
    return new Promise<ImagesResource>((resolve, reject) => {
      ImagesResourceApi.insertItem(this.formData)
        .then((response) => {
          this.showTab = false
          setInterval(() => { this.showTab = true }, 50)
          this.pageState = 'view'
          this.tabName = 'name2'
          this.$Message.success('保存成功')
          resolve(response.data!)
        })
        .catch((err: Error) => {
          this.$Message.error(err.toString())
          reject(err)
        })
    })
  }

  /**
   * 保存修改数据
   */
  onSaveFormData (): Promise<ImagesResource> {
    const that = this
    this.formData.title = this.formData.name
    return new Promise<ImagesResource>((resolve, reject) => {
      ImagesResourceApi.updateItem(this.formData)
        .then((response) => {
          resolve(response.data!)
          that.appInstanceId = response.data!.id!
          this.$Message.success('修改成功')
          this.$forceUpdate()
        })
        .catch((err: Error) => {
          this.$Message.error(err.toString())
          reject(err)
        })
    })
  }

  @Watch('imageType')
  changeImageTypeName (newValue: string) {
    this.formData.imageTypeName = newValue
  }

  get imageType () {
    switch (this.formData.imageType) {
      case 1:
        return '注册中心'
      case 2:
        return '网关'
      case 3:
        return '配置中心'
      case 4:
        return '安全中心'
      case 5:
        return '关系数据库'
      case 6:
        return '缓存数据库'
      case 7:
        return 'nosql数据库'
      case 8:
        return '消息服务'
      case 9:
        return 'naginx'
      default:
        return '其他'
    }
  }
}
